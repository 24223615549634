/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
// import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
// import TextField from "@material-ui/core/TextField";
// import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import axios from "axios";
// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import projectsTableData from "layouts/users/data/projectsTableData";
import { useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

function ChangePassword() {
  const route = useLocation().pathname.split("/").slice(1);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [dialogsucesss, setOpen1] = React.useState(false);
  const [dialogloading, setOpen3] = React.useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const checkIsValid = () => {
    if (password.length < 8) {
      setIsValidPassword(false);
    } else {
      setIsValidPassword(true);
    }
  };
  const checkIsConfirmed = () => {
    if (password2 === password) {
      setIsConfirmed(true);
    } else {
      setIsConfirmed(false);
    }
  };

  useEffect(() => {
    checkIsValid();
    checkIsConfirmed();
  }, [password, password2]);
  const handleClickOpenLoading = () => {
    setOpen3(true);
  };
  const handleCloseLoading = () => {
    setOpen3(false);
  };
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClickOpenSuccess = () => {
    setOpen1(true);
  };

  const handleCloseSuccess = () => {
    setOpen1(false);
  };

  function editUserPassword() {
    const token = sessionStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}`, ContentType: `application/json` };
    axios
      .post(
        "https://trust-cargo.co/anas_backend/api/change-password",
        {
          new_password: password,
          new_password_confirmation: password2,
        },
        { headers }
      )
      .then((response) => {
        handleClickOpenSuccess();
        navigate(`/messages`);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Change Password</SuiTypography>
            </SuiBox>
            <div style={{ width: "400px", margin: "20px" }}>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  New Password
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </div>
              {!isValidPassword && (
                <div style={{ fontSize: "13px", color: "#d50000" }}>
                  Password must be 8 characters at laest
                </div>
              )}
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Confirm New Password
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter Password"
                  onChange={(e) => setPassword2(e.target.value)}
                  value={password2}
                />
              </div>
              {password2.length > 0 && (
                <div>
                  {isConfirmed === true ? (
                    <div style={{ fontSize: "13px", color: "#1b5e20" }}>Password Confirmed!</div>
                  ) : (
                    <div style={{ fontSize: "13px", color: "#d50000" }}>
                      Password not Confirmed!
                    </div>
                  )}
                </div>
              )}
              <div>
                <Button
                  variant="contained"
                  style={{ margin: "20px" }}
                  onClick={() => {
                    handleClickOpenLoading();
                    editUserPassword();
                  }}
                >
                  {t("save")}
                </Button>
                <Dialog
                  open={dialogsucesss}
                  onClose={handleCloseSuccess}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">تم تعديل المستخدم بنجاح</DialogTitle>
                  <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
                  </DialogContent>
                  <DialogActions>
                    {/* <Button onClick={handleClose}>ok</Button> */}
                    <Button
                      onClick={() => {
                        navigate(`/users`);
                      }}
                      autoFocus
                    >
                      {t("ok")}
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={dialogloading}
                  onClose={handleCloseLoading}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <ReactLoading color="blue" height={50} width={50} type="spokes" />
                </Dialog>
              </div>
            </div>
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ChangePassword;
