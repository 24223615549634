/* eslint-disable object-shorthand */

import useDelete from "hooks/general/useDelete";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const useCustomers = () => {
  const { t } = useTranslation();
  const [loading, setloading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [loadingId, setLoadingId] = useState(null);
  const fetchData = async () => {
    // const token = sessionStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
    };
    // const headers = { Authorization: `Bearer ${token}`, ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/customers", { headers });
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setCustomers(data["customers"]);
    setloading(false);
  };
  useEffect(() => {
    setloading(true);
    fetchData();
  }, []);
  const { dialogdelete, setDialogDelete, openDialog, closeDialog, executeDelete } = useDelete(
    "remove_customer",
    fetchData
  );
  const navigate = useNavigate();

  const handleChangeCanSeeArrival = async (id, status) => {
    setLoadingId(id);
    const updatedCustomer = customers.map((customer) =>
      customer.id === id
        ? { ...customer, can_see_arrival_date: status === "true" ? "false" : "true" }
        : customer
    );
    setCustomers(updatedCustomer);
    const customer = customers.find((customer) => customer.id === id);
    if (customer) {
      const newArrival = status === "true" ? "false" : "true";
      try {
        await axios.post(`https://trust-cargo.co/anas_backend/api/update-can-see-arrival-date`, {
          can_see_arrival_date: newArrival,
          id: id,
        });
        setLoadingId(null);
      } catch (error) {
        console.error("Error changing order show number status:", error);
        const failedCustomerUpdate = customers.map((customer) =>
          customer.id === id ? { ...customer, can_see_arrival_date: status } : customer
        );
        setLoadingId(null);
        setCustomers(failedCustomerUpdate);
      }
    }
  };
  return {
    t,
    loading,
    customers,
    fetchData,
    navigate,
    dialogdelete,
    setDialogDelete,
    openDialog,
    closeDialog,
    executeDelete,
    handleChangeCanSeeArrival,
    loadingId,
  };
};

export default useCustomers;
