/* eslint-disable react/forbid-prop-types */

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";

export default function EditMessageSuccess({ body1, customer1, file1 }) {
  EditMessageSuccess.propTypes = {
    body1: PropTypes.string.isRequired,
    customer1: PropTypes.string.isRequired,
    file1: PropTypes.any.isRequired,
  };
  const route = useLocation().pathname.split("/").slice(1);
  const [dialogsucesss, setOpen1] = React.useState(false);
  const [dialogloading, setOpen3] = React.useState(false);
  const handleClickOpenLoading = () => {
    setOpen3(true);
  };
  const handleCloseLoading = () => {
    setOpen3(false);
  };
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClickOpenSuccess = () => {
    setOpen1(true);
  };

  const handleCloseSuccess = () => {
    setOpen1(false);
  };

  function editUser() {
    const headers = { ContentType: `application/json` };
    const formData = new FormData();
    formData.append("id", route[route.length - 1]);
    formData.append("body", body1);
    formData.append("customer_id", customer1);
    formData.append("message_file", file1);
    axios
      .post("https://trust-cargo.co/anas_backend/api/edit_message", formData, { headers })
      .then((response) => {
        console.log(response);
        console.log("success");
        handleClickOpenSuccess();
        // navigate(`/users`);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <div>
      <Button
        variant="contained"
        style={{ margin: "20px" }}
        onClick={() => {
          handleClickOpenLoading();
          editUser();
        }}
      >
        {t("save")}
      </Button>
      <Dialog
        open={dialogsucesss}
        onClose={handleCloseSuccess}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">تم تعديل الرساله بنجاح</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button
            onClick={() => {
              navigate(`/messages`);
            }}
            autoFocus
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogloading}
        onClose={handleCloseLoading}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ReactLoading color="blue" height={50} width={50} type="spokes" />
      </Dialog>
    </div>
  );
}
