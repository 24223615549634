/* eslint-disable no-unused-vars */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import * as React from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import axios from "axios";
// Soft UI Dashboard React examples
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function LogOut() {
  const [dialogsucesss, setOpen1] = useState(false);
  const navigate = useNavigate();

  const handleClickOpenSuccess = () => {
    setOpen1(true);
  };

  const handleCloseSuccess = () => {
    setOpen1(false);
  };
  const route = useLocation().pathname.split("/").slice(1);
  const { t } = useTranslation();
  const [dialogdelete, setOpen] = React.useState(false);
  const dialogtrue = () => {
    setOpen(true);
  };
  const dialogfalse = () => {
    setOpen(false);
  };

  return (
    <>
      <div style={{ width: "150px", margin: "50px", color: "green" }}>
        <Button
          variant="contained"
          style={{ color: "white" }}
          onClick={() => {
            dialogtrue();
          }}
        >
          Logout
        </Button>
      </div>
      <Dialog
        open={dialogdelete}
        onClose={dialogfalse}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure want to logout?</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
Let Google help apps determine location. This means sending anonymous location data to
Google, even when no apps are running.
</DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button
            onClick={() => {
              dialogfalse();
              sessionStorage.setItem("log_status", "false");
              navigate("/homescreen");
            }}
            autoFocus
          >
            {t("yes")}
          </Button>
          <Button
            onClick={() => {
              dialogfalse();
            }}
            autoFocus
          >
            {t("no")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default LogOut;
