/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable dot-notation */
import React, { useState } from "react";
import axios from "axios";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
// Authentication layout components
import { useTranslation } from "react-i18next";
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import { useNavigate } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import SuiButton from "components/SuiButton";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);
  const { t } = useTranslation();
  const [loading, setloading] = useState(false);
  const [dialogsucesss, setOpen1] = React.useState(false);
  const [dialogempty, setOpen2] = React.useState(false);
  const [dialogfailed, setOpen3] = React.useState(false);
  const navigate = useNavigate();

  const handleClickOpenSuccess = () => {
    setOpen1(true);
  };

  const handleClickOpenEmpty = () => {
    setOpen2(true);
  };
  const handleOpenfailed = () => {
    setOpen3(true);
  };

  const handleCloseSuccess = () => {
    setOpen1(false);
  };
  const handleClosefailed = () => {
    setOpen3(false);
  };
  const handleCloseEmpty = () => {
    setOpen2(false);
  };
  function login() {
    if (
      document.getElementById("email").value === "" ||
      document.getElementById("password").value === ""
    ) {
      handleClickOpenEmpty();
      setloading(false);
    } else {
      const token = sessionStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}`, ContentType: `application/json` };
      axios
        .post(
          "https://trust-cargo.co/anas_backend/api/login",
          {
            email: document.getElementById("email").value,
            password: document.getElementById("password").value,
          },
          { headers }
        )
        .then((response) => {
          console.log(response);
          sessionStorage.setItem("token", response.data.access_token);
          sessionStorage.setItem("log_status", response.data.status);
          sessionStorage.setItem("role_id", response.data["user"]["role_id"]);
          response.data["user"]["role_id"] === "customer"
            ? sessionStorage.setItem("customer_id", response.data["user"]["customer"]["id"])
            : null;
          response.data["user"]["role_id"] === "customer"
            ? sessionStorage.setItem("customer_code", response.data["user"]["customer"]["code"])
            : null;
          response.data["user"]["role_id"] === "customer"
            ? sessionStorage.setItem("customer_name", response.data["user"]["customer"]["name"])
            : null;
          handleClickOpenSuccess();
          setloading(false);
          sessionStorage.getItem("role_id") === "admin"
            ? navigate("/users")
            : navigate("/messages");
        })
        .catch((error) => {
          handleOpenfailed();
          setloading(false);
        });
    }
  }

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  return (
    <CoverLayout title={t("wel_back")} description={t("email_password_login")} image={curved9}>
      <SuiBox component="form" role="form">
        <SuiBox mb={2}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              {t("email")}
            </SuiTypography>
          </SuiBox>
          <SuiInput
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setloading(true);
                login();
              }
            }}
            type="email"
            placeholder="Email"
            id="email"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              {t("password")}
            </SuiTypography>
          </SuiBox>
          <SuiInput
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setloading(true);
                login();
              }
            }}
            type="password"
            placeholder="Password"
            id="password"
          />
        </SuiBox>

        <div>
          {loading ? (
            <Spinner style={{ marginBottom: 27 }} animation="border" variant="danger" />
          ) : (
            <SuiBox mt={4} mb={1}>
              <SuiButton
                variant="gradient"
                color="info"
                onClick={() => {
                  setloading(true);
                  login();
                }}
                fullWidth
              >
                {t("login")}
              </SuiButton>
            </SuiBox>
          )}

          {/* <Button onClick={() => this.toggleLoader()} variant="primary" size="lg">
        {loading ? "Hide Loader" : "Show Loader"}
      </Button> */}

          <Dialog
            open={dialogsucesss}
            onClose={handleCloseSuccess}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">تم تسجيل الدخول بنجاح</DialogTitle>
            <DialogContent>
              {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  sessionStorage.getItem("role_id") === "admin"
                    ? navigate("/users")
                    : navigate("/messages");
                }}
                autoFocus
              >
                {t("ok")}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={dialogempty}
            onClose={handleCloseEmpty}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">الرجاء تعبئه جميع البيانات</DialogTitle>
            <DialogContent>
              {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleClose}>ok</Button> */}
              <Button onClick={handleCloseEmpty} autoFocus>
                {t("ok!!")}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={dialogfailed}
            onClose={handleClosefailed}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              فشلت عمليه تسجيل الدخول , الرجاء المحاوله مره اخرى
            </DialogTitle>
            <DialogContent>
              {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleClose}>ok</Button> */}
              <Button onClick={handleClosefailed} autoFocus>
                {t("ok!!")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {/* <SuiBox mt={3} textAlign="center">
          <SuiTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SuiTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SuiTypography>
          </SuiTypography>
        </SuiBox> */}
      </SuiBox>
    </CoverLayout>
  );
}

export default SignIn;
