/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";
import VirtualReality from "layouts/virtual-reality";
import RTL from "layouts/rtl";

// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
// import CreditCard from "examples/Icons/CreditCard";
import Cube from "examples/Icons/Cube";

import BackUpData from "layouts/back_up";
import TestUsers from "layouts/users/test";
import ReciveGoods from "layouts/recive_goods";
import Totals from "layouts/totals/totals";
import CustomerOrders from "layouts/customer_orders";
import AllOrdersCustomers from "layouts/all_orders_customers";

// auth routes
import EditCurrentUser from "layouts/edit_current_user";
import EditPasswordUser from "layouts/edit_password_current_user";
import HomeFive from "layouts/home_screen";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// users routes
import Users from "layouts/users";
import AddUser from "layouts/users/add_user";
import EditUser from "layouts/users/edit_user";

// customers routes
import Customers from "layouts/customers";
import AddCustomer from "layouts/customers/add_customer";
import EditCustomer from "layouts/customers/edit_customer";

// containers routes
import Containers from "layouts/containers";
import ViewContainer from "layouts/containers/view_container";
import EditContainer from "layouts/containers/edit_container";
import ContainersDetails from "layouts/containers/container_details";
import AddContainer from "layouts/containers/add_container";

// orders routes
import Orders from "layouts/orders";
import AllOrders from "layouts/orders/all_orders_details";
import EditOrderMainData from "layouts/orders/edit_order_main_data";
import AddOrder from "layouts/orders/add_order";
import AddOrderFromExcel from "layouts/orders/add_order_from_excel";
import ViewOrder from "layouts/orders/view_order";
import EditOrder from "layouts/orders/edit_order";

// posts routes
import Posts from "layouts/posts";
import AddPost from "layouts/posts/add_post";
import EditPost from "layouts/posts/edit_post";
import SinglePost from "layouts/posts/single_post";

// messages routes
import Messages from "layouts/messages";
import AddMessage from "layouts/messages/add_message";
import EditMessage from "layouts/messages/edit_message";

// status routes
import Status from "layouts/status";
import AddStatus from "layouts/status/add_status";
import EditStatus from "layouts/status/edit_status";

// transactions routes
import Transactions from "layouts/transactions";
import AddTransaction from "layouts/transactions/add_transaction";
import AllOrdersArchive from "layouts/orders/orders_archive";
import ChangePassword from "layouts/users/edit_user_password";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Home Screen",
    key: "homescreen",
    route: "/homescreen",
    icon: <Shop size="12px" />,
    component: <HomeFive />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Containers",
    key: "containers",
    route: "/containers",
    icon: <Office size="12px" />,
    component: <Containers />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Status",
    key: "status",
    route: "/status",
    icon: <Office size="12px" />,
    component: <Status />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Posts",
    key: "posts",
    route: "/posts",
    icon: <Office size="12px" />,
    component: <Posts />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/users",
    icon: <Office size="12px" />,
    component: <Users />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    route: "/customers",
    icon: <Office size="12px" />,
    component: <Customers />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Messages",
    key: "messages",
    route: "/messages",
    icon: <Office size="12px" />,
    component: <Messages />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "All Orders",
    key: "all_orders",
    route: "/all_orders",
    icon: <Office size="12px" />,
    component: <AllOrders />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "All Orders Archive",
    key: "all_orders_archive",
    route: "/all_orders_archive",
    icon: <Office size="12px" />,
    component: <AllOrdersArchive />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Transactions",
    key: "transactions",
    route: "/transactions",
    icon: <Office size="12px" />,
    component: <Transactions />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Orders",
    key: "orders",
    route: "/orders",
    icon: <Office size="12px" />,
    component: <Orders />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Customer Orders",
    key: "customer_orders",
    route: "/customer_orders",
    icon: <Office size="12px" />,
    component: <CustomerOrders />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Container Details",
    key: "container_orders",
    route: "/container_orders/:id",
    icon: <Office size="12px" />,
    component: <ContainersDetails />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Customer Order Details",
    key: "customer_order_details",
    route: "/customer_order_details",
    icon: <Office size="12px" />,
    component: <AllOrdersCustomers />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Change Password",
    key: "change_password",
    route: "/change_password",
    icon: <Office size="12px" />,
    component: <ChangePassword />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "View Order",
    key: "view_order",
    route: "/view_order/:id",
    icon: <Office size="12px" />,
    component: <ViewOrder />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "View Container",
    key: "view_container",
    route: "/view_container/:id",
    icon: <Office size="12px" />,
    component: <ViewContainer />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Edit Container",
    key: "edit_container",
    route: "/edit_container/:id",
    icon: <Office size="12px" />,
    component: <EditContainer />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Edit Message",
    key: "edit_message",
    route: "/edit_message/:id",
    icon: <Office size="12px" />,
    component: <EditMessage />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Virtual Reality",
    key: "virtual-reality",
    route: "/virtual-reality",
    icon: <Cube size="12px" />,
    component: <VirtualReality />,
    noCollapse: true,
  },

  { type: "title", title: "Account Pages", key: "account-pages" },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <Document size="12px" />,
    component: <SignIn />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    route: "/authentication/sign-up",
    icon: <SpaceShip size="12px" />,
    component: <SignUp />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Users",
    key: "add_user",
    route: "/add_user",
    icon: <SpaceShip size="12px" />,
    component: <AddUser />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Edit User",
    key: "edit_user",
    route: "/edit_user/:id",
    icon: <SpaceShip size="12px" />,
    component: <EditUser />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Edit Order",
    key: "edit_order",
    route: "/edit_order/:id",
    icon: <SpaceShip size="12px" />,
    component: <EditOrder />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Edit Order",
    key: "edit_order_data",
    route: "/edit_order_data/:id",
    icon: <SpaceShip size="12px" />,
    component: <EditOrderMainData />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Add Post",
    key: "add_post",
    route: "/add_post",
    icon: <SpaceShip size="12px" />,
    component: <AddPost />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Add Order From Excel",
    key: "add_order_from_excel",
    route: "/add_order_from_excel",
    icon: <SpaceShip size="12px" />,
    component: <AddOrderFromExcel />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Add Message",
    key: "add_message",
    route: "/add_message",
    icon: <SpaceShip size="12px" />,
    component: <AddMessage />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Add Status",
    key: "add_status",
    route: "/add_status",
    icon: <SpaceShip size="12px" />,
    component: <AddStatus />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Edit Status",
    key: "edit_status",
    route: "/edit_status/:id",
    icon: <SpaceShip size="12px" />,
    component: <EditStatus />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Add Transaction",
    key: "add_transaction",
    route: "/add_transaction",
    icon: <SpaceShip size="12px" />,
    component: <AddTransaction />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Add Order",
    key: "add_order",
    route: "/add_order",
    icon: <SpaceShip size="12px" />,
    component: <AddOrder />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Recive Goods",
    key: "recive_goods",
    route: "/recive_goods",
    icon: <SpaceShip size="12px" />,
    component: <ReciveGoods />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Totals",
    key: "totals",
    route: "/totals",
    icon: <Office size="12px" />,
    component: <Totals />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Add Container",
    key: "add_container",
    route: "/add_container",
    icon: <SpaceShip size="12px" />,
    component: <AddContainer />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Add Customer",
    key: "add_customer",
    route: "/add_customer",
    icon: <SpaceShip size="12px" />,
    component: <AddCustomer />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Edit Password Current User",
    key: "edit_current_user_password",
    route: "/edit_current_user_password",
    icon: <SpaceShip size="12px" />,
    component: <EditPasswordUser />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Edit Customer",
    key: "edit_customer",
    route: `/edit_customer/:customer_id`,
    icon: <SpaceShip size="12px" />,
    component: <EditCustomer />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Edit Current User",
    key: "edit_current_user",
    route: "/edit_current_user",
    icon: <SpaceShip size="12px" />,
    component: <EditCurrentUser />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Payments",
    key: "payments",
    route: "/payments",
    icon: <Document size="12px" />,
    component: <Containers />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Edit Post",
    key: "edit_post",
    route: "/edit_post",
    // icon: <Document size="12px" />,
    component: <EditPost />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Back Up",
    key: "back_up",
    route: "/back_up",
    icon: <Document size="12px" />,
    component: <BackUpData />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "TestANas",
    key: "back_up",
    route: "/back_up",
    icon: <Document size="12px" />,
    component: <TestUsers />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "TestANas",
    key: "back_up",
    route: "/view_post/:id",
    icon: <Document size="12px" />,
    component: <EditPost />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "TestANas",
  //   key: "back_up",
  //   route: "/single-post/:id",
  //   icon: <Document size="12px" />,
  //   component: <SinglePost />,
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "Single Post",
    key: "single post",
    route: "/single-post/:id",
    icon: <Shop size="12px" />,
    component: <SinglePost />,
    noCollapse: true,
  },
];

export default routes;
