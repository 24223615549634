/* eslint-disable no-template-curly-in-string */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import {
  Box,
  // TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Checkbox,
  // Paper,
} from "@mui/material";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import useCustomers from "hooks/customers/useCustomers";
import DeleteDialog from "components/deleteDialog";
import ReactLoading from "react-loading";

function Customers() {
  const {
    t,
    loading,
    customers,
    navigate,
    dialogdelete,
    openDialog,
    closeDialog,
    executeDelete,
    handleChangeCanSeeArrival,
    loadingId,
  } = useCustomers();
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            {/* <SuiBox display="flex" justifyContent="space-between" alignItems="start" p={3}>
              <SuiTypography variant="h6">Customers</SuiTypography>
            </SuiBox> */}
            {/* <mycomponent /> */}
            <div style={{ width: "200px", margin: "20px", color: "green" }}>
              <Button
                variant="contained"
                style={{ color: "white" }}
                onClick={() => {
                  navigate("/add_customer");
                }}
              >
                Add Customer
              </Button>
            </div>
            {loading ? (
              <div
                style={{
                  width: "1000",
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <progress className="pure-material-progress-circular" />
              </div>
            ) : (
              <Table sx={{ minWidth: 1000 }} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ display: "flex" }}>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 7 }} align="start">
                        Name
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 7 }} align="start">
                        Phone Number
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 7 }} align="start">
                        Address
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 7 }} align="start">
                        Code
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 7 }} align="start">
                        Vat Name
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 7 }} align="start">
                        User Name
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 7 }} align="start">
                        See Arrival Date
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 7 }} align="start">
                        {t("operations")}
                      </TableCell>
                    </Box>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customers.map((user) => (
                    <TableRow
                      key={user.id}
                      sx={{
                        // "&:last-child td, &:last-child th": { border: 0 },
                        display: "flex",
                      }}
                    >
                      <Box>
                        <TableCell sx={{ width: window.innerWidth / 7 }} align="start">
                          {user.name ?? "-"}
                        </TableCell>
                      </Box>
                      <Box>
                        <TableCell sx={{ width: window.innerWidth / 7 }} align="start">
                          {user.phone_number ?? "-"}
                        </TableCell>
                      </Box>
                      <Box>
                        <TableCell sx={{ width: window.innerWidth / 7 }} align="start">
                          {user.address ?? "-"}
                        </TableCell>
                      </Box>
                      <Box>
                        <TableCell align="start" sx={{ width: window.innerWidth / 7 }}>
                          {user.code ?? "-"}
                        </TableCell>
                      </Box>
                      <Box>
                        <TableCell sx={{ width: window.innerWidth / 7 }} align="start">
                          {user.vat_name ?? "-"}
                        </TableCell>
                      </Box>
                      <Box>
                        <TableCell sx={{ width: window.innerWidth / 7 }} align="start">
                          {user.user_id ?? "-"}
                        </TableCell>
                      </Box>
                      <Box sx={{ width: window.innerWidth / 11 }} align="start">
                        <TableCell sx={{ width: window.innerWidth / 7 }} align="start">
                          {loadingId === user.id ? (
                            <ReactLoading color="blue" height={20} width={20} type="spokes" />
                          ) : (
                            <Checkbox
                              checked={user.can_see_arrival_date === "true" || false}
                              onChange={() =>
                                handleChangeCanSeeArrival(user.id, user.can_see_arrival_date)
                              }
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          )}
                        </TableCell>
                      </Box>
                      <Box>
                        <TableCell sx={{ width: window.innerWidth / 7 }} align="start">
                          <SuiTypography
                            component="a"
                            href="#"
                            variant="caption"
                            color="secondary"
                            fontWeight="medium"
                            onClick={() => {
                              navigate(`/edit_customer/${user.id}`);
                            }}
                          >
                            {t("edit")}
                          </SuiTypography>
                          <SuiTypography
                            component="a"
                            variant="caption"
                            color="primary"
                            fontWeight="medium"
                            margin="15px"
                            onClick={() => {
                              openDialog(user.id);
                            }}
                          >
                            {t("delete")}
                          </SuiTypography>{" "}
                        </TableCell>
                      </Box>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}

            {/* </TableContainer> */}
            <DeleteDialog
              dialogdelete={dialogdelete}
              closeDialog={closeDialog}
              deleteItem={executeDelete}
            />
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Customers;
