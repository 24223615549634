import useCustomers from "hooks/customers/useCustomers";
import { useState } from "react";
import axios from "axios";
import useFormValidation from "hooks/general/useFormValidation";

const useMessageAdding = () => {
  const [customer, setCustomer] = useState("");
  const { customers } = useCustomers();
  const [body, setBody] = useState("");
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogError, setDialogError] = useState(false);

  function addMessage() {
    const headers = { ContentType: `application/json` };
    const formData = new FormData();
    formData.append("body", body);
    formData.append("customer_id", customer);
    formData.append("message_file", file);
    axios
      .post("https://trust-cargo.co/anas_backend/api/add_message", formData, { headers })
      .then((response) => {
        console.log(response);
        setLoading(false);
        setDialogSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setDialogError(true);
      });
  }

  const { handleSubmit, validated } = useFormValidation(addMessage);

  return {
    customer,
    setCustomer,
    customers,
    body,
    setBody,
    loading,
    dialogSuccess,
    setDialogSuccess,
    dialogError,
    setDialogError,
    handleSubmit,
    validated,
    file,
    setFile,
  };
};

export default useMessageAdding;
