/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-template-curly-in-string */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  // Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import useMessages from "hooks/messages/useMessages";
import DeleteDialog from "components/deleteDialog";

function Messages() {
  const {
    t,
    navigate,
    loading,
    messages,
    fetchData,
    dialogdelete,
    setDialogDelete,
    openDialog,
    closeDialog,
    executeDelete,
  } = useMessages();
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            {/* <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Messages</SuiTypography>
            </SuiBox> */}
            {sessionStorage.getItem("role_id") === "admin" ? (
              <div style={{ width: "200px", margin: "20px", color: "green" }}>
                <Button
                  variant="contained"
                  style={{ color: "white" }}
                  onClick={() => {
                    navigate("/add_message");
                  }}
                >
                  Add Message
                </Button>
              </div>
            ) : null}
            {loading ? (
              <div
                style={{
                  width: "1000",
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <progress className="pure-material-progress-circular" />
              </div>
            ) : (
              <Table sx={{ minWidth: 1000 }} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ display: "flex" }}>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 3 }} align="center">
                        body
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 3 }} align="center">
                        Customer
                      </TableCell>
                    </Box>
                    {sessionStorage.getItem("role_id") === "admin" ? (
                      <Box>
                        <TableCell sx={{ width: window.innerWidth / 3 }} align="center">
                          {t("operations")}
                        </TableCell>
                      </Box>
                    ) : null}
                  </TableRow>
                </TableHead>
                {messages && messages.length > 0 ? (
                  <TableBody>
                    {messages.map((user) => (
                      <TableRow
                        key={user.id}
                        style={{ marginLeft: "20px" }}
                        sx={{
                          // "&:last-child td, &:last-child th": { border: 0 },
                          display: "flex",
                        }}
                      >
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 3 }} align="center">
                            <div dangerouslySetInnerHTML={{ __html: user.body }} />
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 3 }} align="center">
                            {user.customer_name ?? "-"}
                          </TableCell>
                        </Box>
                        {sessionStorage.getItem("role_id") === "admin" ? (
                          <Box>
                            <TableCell
                              sx={{ width: window.innerWidth / 3 }}
                              align="center"
                              style={{ flexDirection: "row" }}
                            >
                              <SuiTypography
                                component="a"
                                href="#"
                                variant="caption"
                                color="secondary"
                                fontWeight="medium"
                                onClick={() => {
                                  navigate(`/edit_message/${user.id}`);
                                }}
                              >
                                {t("edit")}
                              </SuiTypography>
                              <SuiTypography
                                component="a"
                                variant="caption"
                                color="primary"
                                fontWeight="medium"
                                margin="15px"
                                onClick={() => {
                                  openDialog(user.id);
                                }}
                              >
                                {t("delete")}
                              </SuiTypography>{" "}
                            </TableCell>
                          </Box>
                        ) : null}
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 2 }} align="center">
                        There is no data to display
                      </TableCell>
                    </Box>
                  </TableBody>
                )}
              </Table>
            )}
            <DeleteDialog
              dialogdelete={dialogdelete}
              closeDialog={closeDialog}
              deleteItem={executeDelete}
            />
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Messages;
